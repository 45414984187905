import { create } from '@storybook/theming'

// motif_green: #33db86

export default create({
  base: 'light',
  brandImage: `https://storage.googleapis.com/assets-store-bucket/img/logo/liveramp-motif.svg`,
  brandTitle: 'Motif',
  fontBase: '"Open Sans", sans-serif',
  fontCode: '"IBM Plex Mono", monospace',
})
